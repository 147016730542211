import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "./components/DevelopmentTable";
import {
  columnsDataDevelopment,
} from "./variables/columnsData";
import React, { useEffect, useState } from "react";
import { generalReportsData } from "./services";
import './TabsComponent.css';

export default function GridComponent({ code }) {
  const [headTableData, setHeadTableData] = useState([]);
  const [headTableConfig, setHeadTableConfig] = useState([]);
  const [tableDataModel, setTableDataModel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeTab, setActiveTab] = useState(null);

  const loadData = async () => {
    try {
      setErrorMessage("");
      const response = await generalReportsData(code);
      setHeadTableData(
        response.result.headers.map((value) => {
          return {
            Header: value.name,
            accessor: value.field,
          };
        })
      );
      setHeadTableConfig(response.result.headers);
      setTableDataModel(response.result.data);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setErrorMessage("Error al consultar los datos");
      setLoading(false)
    }
  };
  
  useEffect(() => {
    setLoading(true)
    loadData();
  }, [code]);


  const reloadList = () => {
    setLoading(true)
    loadData();
  };

  return (<div>
    {errorMessage != ""
      ? (<p
        style={{
          textAlign: "center",
          padding: "40px",
          marginTop: "40px",
          border: "2px red dashed",
          borderRadius: "20px",
          fontSize: "25px",
          color: "red",
          fontStyle: "italic",
          fontWeight: "500",
        }}
      >{errorMessage}</p>)
      : (<Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid
            mb='20px'
            columns={{ sm: 1, md: 1 }}
            spacing={{ base: "20px", xl: "20px" }}>
            <DevelopmentTable
              // columnsData={columnsDataDevelopment}
              columnsData={headTableData}
              loading={loading}
              tableData={tableDataModel}
              reloadList={reloadList}
              headConfig={headTableConfig}
            />
          </SimpleGrid>
        </Box>)
    }
  </div>);

  // return (
  //   <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
  //     <SimpleGrid
  //       mb='20px'
  //       columns={{ sm: 1, md: 1 }}
  //       spacing={{ base: "20px", xl: "20px" }}>
  //        <DevelopmentTable
  //         // columnsData={columnsDataDevelopment}
  //         columnsData={headTableData}
  //         loading={loading}
  //         tableData={tableDataModel}
  //         reloadList={reloadList}
  //         headConfig={headTableConfig}
  //       />
  //     </SimpleGrid>
  //   </Box>
  // );
}
