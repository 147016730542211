import axios from 'utils/axios';


export const listDataUsersActive = async () => {
    try {
      const response = await axios.post('/api/admin/reports/activeUsers', );
      console.log('Respuesta del servidor:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error al hacer la solicitud POST:', error);
      throw error;
    }
};

export const generalReportsOptions = async () => {
    try {
      const response = await axios.post('/api/admin/reports/generalReportsOptions', );
      console.log('Respuesta del servidor:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error al hacer la solicitud POST:', error);
      throw error;
    }
};

export const generalReportsData = async (code) => {
    try {
      const response = await axios.post('/api/admin/reports/generalReportsData', {
        code: code,
      });
      console.log('Respuesta del servidor:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error al hacer la solicitud POST:', error);
      throw error;
    }
};
  
  