import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "./components/DevelopmentTable";
import {
  columnsDataDevelopment,
} from "./variables/columnsData";
import React, { useEffect, useState } from "react";
import { generalReportsOptions } from "./services";
import './TabsComponent.css';
import GridComponent from './component-grid.jsx';

export default function Settings() {
  const [headTableData, setHeadTableData] = useState([]);
  const [headTableConfig, setHeadTableConfig] = useState([]);
  const [tableDataModel, setTableDataModel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [tabsItems, setTabsItems] = useState([]);

  const loadData = async () => {
    try {
      const response = await generalReportsOptions();
      setTabsItems(
        response.result.map((value) => {
          return {
            title: value.title,
            code: value.code,
          };
        })
      );
      setActiveTab(0);
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setLoading(false)
    }
  };
  
  useEffect(() => {
    setLoading(true)
    loadData();
  }, []);


  const reloadList = () => {
    // setLoading(true)
    // loadData();
  };

  return (
    <div>
      <div className="tabs">
        {tabsItems.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={activeTab === index ? "activeTab" : "inactiveTab"}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {activeTab !== null ? (
        <GridComponent
          code={tabsItems[activeTab].code}
        />
        // <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        //   <SimpleGrid
        //     mb='20px'
        //     columns={{ sm: 1, md: 1 }}
        //     spacing={{ base: "20px", xl: "20px" }}>
        //     <DevelopmentTable
        //       // columnsData={columnsDataDevelopment}
        //       columnsData={headTableData}
        //       loading={loading}
        //       tableData={tableDataModel}
        //       reloadList={reloadList}
        //       headConfig={headTableConfig}
        //     />
        //   </SimpleGrid>
        // </Box>
      ) : (
        <p>No se pudo cargar la información</p>
      )}
    </div>
  );

  // return (
  //   <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
  //     <SimpleGrid
  //       mb='20px'
  //       columns={{ sm: 1, md: 1 }}
  //       spacing={{ base: "20px", xl: "20px" }}>
  //        <DevelopmentTable
  //         // columnsData={columnsDataDevelopment}
  //         columnsData={headTableData}
  //         loading={loading}
  //         tableData={tableDataModel}
  //         reloadList={reloadList}
  //         headConfig={headTableConfig}
  //       />
  //     </SimpleGrid>
  //   </Box>
  // );
}
