import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";

// Admin Imports
import Holidays from "views/admin/holidays";
import MessageApplication from "views/admin/message-application";
import Params from "views/admin/params";
import TemplateContract from "views/admin/template-contract";
import TutorialVideo from "views/admin/tutorial-video";
import TemplateEventoCalendar from "views/admin/template-event-calendar";
import ReportGeneral from "views/admin/report-general";
import TemplateGeneral from "views/admin/template-general";
import Previred from "views/admin/previred";
import Users from "views/admin/users";
import signIn from "views/auth/signIn";
// Auth Imports
import SignInCentered from "views/auth/signIn";

const routes = [
  {
    name: "Indicadores previsionales",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/previred",
    component: Previred,
},
  {
    name: "Feriados",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/holidays",
    component: Holidays,
  },
  {
    name: "Mensaje de aplicación",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/message-application",
    component: MessageApplication,
  },
  {
    name: "Parámetros",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/params",
    component: Params,
  },
  {
    name: "Plantilla contratos",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/template-contract",
    component: TemplateContract,
  },
  {
    name: "Tutorial Video",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/tutorial-video",
    component: TutorialVideo,
  },
  {
    name: "Template General",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/template-general",
    component: TemplateGeneral,
  },
  {
    name: "Template Evento Calendar",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/template-evento-calendar",
    component: TemplateEventoCalendar,
  },
  {
    name: "Usuario",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/usuario",
    component: Users,
  },
  {
    name: "Reportes",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    path: "/report-general",
    component: ReportGeneral,
  },
];

export default routes;
