import axios from 'axios';

// axios.defaults.baseURL = "https://j-domestikcoapi-qa-svzojtlx7a-uc.a.run.app";
// axios.defaults.baseURL = "https://j-domestikcoapi-svzojtlx7a-uc.a.run.app";
// axios.defaults.baseURL = "https://5rcfbhhn87.execute-api.us-east-1.amazonaws.com/latest"; //PROD
axios.defaults.baseURL = "https://pwtu73541k.execute-api.us-east-1.amazonaws.com/latest"; //PRE-PROD
// axios.defaults.baseURL = "http://localhost:3000"; //LOCALHOST
// axios.defaults.baseURL = "http://0.0.0.0:3331";

// Configurar el interceptor para agregar el encabezado de autorización
axios.interceptors.request.use(
  (config) => {
    // Obtener el token del almacenamiento local (localStorage)
    const token = localStorage.getItem('token');
    // Si hay un token, agregarlo al encabezado de autorización
    if (token) {
      config.headers['Authorization'] = `${token}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
});

// Exportar Axios configurado
export default axios;
